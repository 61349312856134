import React, { useState } from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { useScrollPosition } from "../../hooks/useScrollPosition"
import ChevronUpIcon from "../../images/icons/chevron-up.svg"
import { ID_HOME } from "../../constants/navLinks"
import LocalLink from "./LocalLink"

const Container = styled("div")`
  ${tw`visibility[hidden] fixed transition[all] bg-gradient duration-300 
  text-center rounded-full p-3 flex-shrink-0
  opacity-0 text-white hover:shadow-lg cursor-pointer outline-none
  `}

  bottom: 1rem;
  right: 1rem;
  z-index: 100;

  svg {
    ${tw`h-5 w-5 md:h-8 md:w-8`}
  }

  &:hover {
    svg {
      ${tw`animate-bounce`}
    }
  }

  ${({ isVisibility }) =>
    isVisibility &&
    tw`
      visibility[visible]
      opacity-100
      `}
`

const ScrollToTop = ({ to = null }) => {
  const [isVisible, setVisible] = useState(false)

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y < -200) {
        setVisible(true)
      } else {
        setVisible(false)
      }
    },
    [setVisible],
    null,
    null,
    200
  )

  return (
    <Container isVisibility={isVisible} onClick={scrollUp}>
      <ChevronUpIcon />
    </Container>
  )
}

export default ScrollToTop
