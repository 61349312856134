import React from "react"
import loadable from "@loadable/component"
import ScrollToTop from "../components/Common/ScrollToTop"

const Seo = loadable(() => import("../components/SEO"))
const Hero = loadable(() => import("../components/Hero"))
const ContactForm = loadable(() => import("../components/ContactForm"))
const PopupContactForm = loadable(() => import("../components/ContactForm/PopupContactForm"))
// const Testimonial = loadable(() => import("../components/Testimonial"))
const Solutions = loadable(() => import("../components/Solutions"))
// const ScrollToTop = loadable(() => import("../components/Common/ScrollToTop"))
// const Features = loadable(() => import("../components/Features"))
// const HowItWorks = loadable(() => import("../components/HowItWorks"))
const Screenshots = loadable(() => import("../components/Screenshots"))
const Pricing = loadable(() => import("../components/Pricing"))
const CookieNotify = loadable(() => import("../components/CookieNotify"))
const FrequentlyAskedQuestions = loadable(() => import("../components/Faq"))

const IndexPage = () => {
  return (
    <>
      <Seo title="Home" />
      <Hero />
      <Solutions />
      {/* <Features />
      <HowItWorks /> */}
      <Screenshots />
      <ScrollToTop />
      {/* <PopupContactForm /> */}
      {/* <Pricing /> */}
      {/* <Testimonial /> */}
      <FrequentlyAskedQuestions />
      {/* <ContactForm /> */}
      <CookieNotify />
    </>
  )
}

export default IndexPage
